<template>
  <div>
    <q-form ref="editForm">
      <c-table
        ref="table"
        :columns="grid.columns"
        :data="grid.data"
        :editable="!disabled"
        :gridHeightAuto="true"
        :columnSetting="false"
        :filtering="false"
        :usePaging="false"
      >
        <!-- 버튼 영역 -->
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='customCol'">
            <!-- tableKey=" " -->
            <component
              :is="imprComponent"
              :col="col"
              :props="props"
              :inputEditable="editable&&!disabled"
              :isImmShow="true"
              :requestContentsCols="requestContentsCols"
              :tableKeys="tableKeys"
              ibmTaskTypeCd="ITT0000075"
              ibmTaskUnderTypeCd="ITTU000130"
              @imprChange="imprChange"
            >
            </component>
          </template>
        </template>
        <template slot="table-header-append">
          <c-field
            :disabled="disabled"
            :editable="editable"
            style="min-width: 150px;max-width: 250px;padding-bottom: 0px !important"
            name="checkUserId"
            placeholder="점검자 선택"
            v-model="checkUserId">
          </c-field>
          &nbsp;&nbsp;
          <c-select
            :editable="editable"
            :comboItems="dateItems"
            type="custom"
            typetxt="일자선택"
            itemText="dayName"
            itemValue="dayCode"
            name="today"
            label=""
            v-model="today"
            @datachange="datechange"
          ></c-select>
          &nbsp;&nbsp;
          <c-select
            :editable="editable"
            :comboItems="afItems"
            type="none"
            itemText="afName"
            itemValue="afCode"
            name="today"
            label=""
            v-model="af"
            @datachange="datechange2"
          ></c-select>
        </template>
      </c-table>
    </q-form>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            :flat="true"
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="safetyCheck.safetyCheckId ? { 
              safetyCheckResultModelList: safetyCheck.safetyCheckResultModelList,
              safetyCheckUserModel: {
                dayId: this.columnKey,
                safetyCheckId: this.param.safetyCheckId, 
                checkUserId: this.checkUserId
              },
            } : this.safetyCheck"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safetyCheckDetail',
  props: {
    param: {
      type: Object,
      default: () => ({
        safetyCheckId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    },
    safetyCheck: {
      type: Object,
      default: () => ({
        safetyCheckId: '',  // 열화점검 일련 번호
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        safetyCheckName: '',  // 점검명
        evaluationCompleteFlag: 'N',  // 결재완료 후 평가완료 처리
        approvalStatusCd: '', // 결재관련 결재상태
        sysApprovalRequestId: '', // 결재관련 결재요청코드
        safetyCheckResultModelList: [], // sheet
        safetyCheckImproveModelList: [], // 개선 list
        safetyCheckUserModelList: [],
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [],
        data: [],
        height: 'auto',
      },
      afItems: [
        {afCode: 'A', afName: '주간'},
        {afCode: 'F', afName: '야간'}
      ],
      af: 'A',
      columnKey: '',
      checkUserId: '',
      dateItems: [],
      currentMonth: '',
      today: null,
      mappingType: 'POST',
      editable: true,
      detailUrl: '',
      saveUrl: '',
      isSave: false,
      isApproval: false,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() {
      return this.safetyCheck.checkStatusCd === 'MCSC000015'
        || this.safetyCheck.approvalStatusCd === 'ASC0000001'
    },
    // 테이블 키 멀티
    tableKeys() {
      return ['safetyCheckResultId','colkey'];
    },
    // 개선요청 내용 전달
    requestContentsCols() {
      return ['checkItemName'];
    },
    // 개선요청 Component
    imprComponent() {
      return () => import(`${'@/pages/common/ibm/mobileTableImpr.vue'}`);
    },
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.safetycheck.plan.get.url;
      this.saveUrl = transactionConfig.sop.safetycheck.result.newItem.url;
      // code setting
      this.currentMonth = this.$comm.getToday().slice(-5, this.$comm.getToday().length -3);
      // list setting
      this.getDetail()
    },
    getDetail() {
      if (this.param.safetyCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.param.safetyCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.safetyCheck, _result.data);
          
          if (this.safetyCheck.checkDate.substring(5) === this.currentMonth) {
            this.today = this.$comm.getToday().slice(-2, this.$comm.getToday().length);
          } else {
            this.today = '01'
          }
          if (String(this.today.charAt(0)) === '0') {
            this.today = this.today.substring(1)
          }
          this.columnKey = this.af === 'A' ? 'day' + this.today : 'nday' + this.today;


          if (this.$_.findIndex(_result.data.safetyCheckUserModelList, {dayId: this.columnKey}) !== -1) {
            let idx = this.$_.findIndex(_result.data.safetyCheckUserModelList, {dayId: this.columnKey});
            this.checkUserId = _result.data.safetyCheckUserModelList[idx].checkUserId;
          } else {
            this.checkUserId = this.$store.getters.user.userId;
          }
          this.$_.forEach(this.safetyCheck.safetyCheckResultModelList, _item => {
            _item.colkey = this.columnKey;
            this.grid.data.push(_item);
          })
          this.setColumns();
        },);
      } 
    },
    setColumns() {
      if (!this.safetyCheck.checkDate) return;
      this.$comm.getComboItems('MIN_SAFETY_RESULT_CD').then(_result => {
        let _columns = [
          {
            name: 'checkItemName',
            field: 'checkItemName',
            label: '점검항목',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkItemCycleName',
            field: 'checkItemCycleName',
            label: '주기',
            align: 'center',
            style: 'width:65px',
            sortable: false,
          },
        ]
        let days = [];
        let year = this.safetyCheck.checkDate.substring(0, 4);
        let month = this.safetyCheck.checkDate.substring(5);

        let date = new Date(Number(year), Number(month), 0);

        for (let i=1; i<=date.getDate(); i++) {
          this.dateItems.push({
            dayName: i + '일',
            dayCode: String(i),
          })
        }
        let label = this.af === 'A' ? '주' : '야';
        days.push({
          name: this.columnKey,
          field: this.columnKey,
          label: this.today + '일('+label +')',
          currentDay: String(this.today) === String(this.today) && String(month) === String(this.currentMonth) 
            ? true : false, 
          align: 'center',
          size: 'xs',
          setHeader: true,
          style: 'width:90px',
          comboItems: _result,
          type: 'select',
          sortable: false,
        })

        this.grid.columns = this.$_.concat(_columns, days, {
          name: 'customCol',
          field: 'customCol',
          label: '',
          align: 'center',
          type: 'custom',
          style: 'width:40px',
          sortable: false
        });
        this.grid.data = this.$_.clone(this.safetyCheck.safetyCheckResultModelList)
            
        this.safetyCheck.safetyCheckName = year + '년  ' + month + '월  관리감독자 안전점검일지'
      })
    },
    saveData() {
      if (this.safetyCheck.safetyCheckId) {
        this.saveUrl = transactionConfig.sop.safetycheck.result.newItem.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.safetycheck.plan.insert.url;
        this.mappingType = 'POST';
      }
      if (this.$comm.validTable(this.grid.columns, this.grid.data)) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?',
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.safetyCheck.regUserId = this.$store.getters.user.userId;
              this.safetyCheck.chgUserId = this.$store.getters.user.userId;
              
              this.safetyCheck.safetyCheckResultModelList = this.$_.clone(this.grid.data);

              if (this.mappingType === 'POST') {
                this.safetyCheck.safetyCheckUserModelList.push({
                  dayId: this.columnKey,
                  safetyCheckId: this.param.safetyCheckId, 
                  checkUserId: this.checkUserId,
                  editFlag: 'C',
                })
              }

              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType === 'POST') {
        this.safetyCheck.safetyCheckId = result.data
        this.returnData.col1 = result.data
        this.$_.forEach(this.grid.data, _item => {
          _item.safetyCheckId = result.data;
          _item.editFlag = 'U';
        })
      }
      this.getDetail();
    },
    datechange() {
      this.columnKey = this.af === 'A' ? 'day' + this.today : 'nday' +this.today; 
      if (this.$_.findIndex(this.safetyCheck.safetyCheckUserModelList, {dayId: this.columnKey}) !== -1) {
        let idx = this.$_.findIndex(this.safetyCheck.safetyCheckUserModelList, {dayId: this.columnKey});
        this.checkUserId = this.safetyCheck.safetyCheckUserModelList[idx].checkUserId;
      } else {
        this.checkUserId = this.$store.getters.user.userId;
      }
      this.$_.forEach(this.safetyCheck.safetyCheckResultModelList, _item => {
        _item.colkey = this.columnKey;
        this.grid.data.push(_item);
      })
      this.setColumns();
    },
    datechange2() {
      this.columnKey = this.af === 'A' ? 'day' + this.today : 'nday' +this.today;
      
      if (this.$_.findIndex(this.safetyCheck.safetyCheckUserModelList, {dayId: this.columnKey}) !== -1) {
        let idx = this.$_.findIndex(this.safetyCheck.safetyCheckUserModelList, {dayId: this.columnKey});
        this.checkUserId = this.safetyCheck.safetyCheckUserModelList[idx].checkUserId;
      } else {
        this.checkUserId = this.$store.getters.user.userId;
      }
      this.$_.forEach(this.safetyCheck.safetyCheckResultModelList, _item => {
        _item.colkey = this.columnKey;
        this.grid.data.push(_item);
      })
      
      this.setColumns();
    },
    closePopUps() {
      this.$emit('closePopup')
    },
    // 개선창 닫기 후
    imprChange() {
      this.getDetail();
      this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
    },
  }
};
</script>

<style lang="sass">
.customDate
  padding-bottom: 0px !important
  .q-field__before
    padding-top: 5px
.custom-text-before
  font-size:14px
  border: 1px solid rgba(0, 0, 0, 0.15) !important
  border-right: 0  !important
  border-radius: 4px 0 0 0
  line-height: 31px
  height: 33px !important
  min-height: 33px !important
.custom-field
  .q-field__native
    cursor: pointer
.q-field--dense .q-field__inner
  padding-bottom: 0px !important
  </style>